import React from "react";
import { mapping, NonValidationInput, ButtonSolid } from "@aim-mf/styleguide";
import { TitleListTable } from "../manageTitle/title/titleListTable";
import { OperatingUnitListTable } from "./OU/operatingUnitListTable";
import { OperatingUnitCreationPopUp } from "./OU/operatingUnitCreationPopUp";
import { OperatingUnitEditPopUp } from "./OU/operatingUnitEditPopUp";
import { TitleCreationPopUp } from "../manageTitle/title/titleCreationPopUp";
import { TitleEditPopUp } from "../manageTitle/title/titleEditPopUp";

// this part i think can use AutoComplete from styleguide

class ManageOUPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ou_name: "",
      createOU: { display: false },
      editOU: { display: false },
    };
  }

  render() {
    return (
      <div>
        <div
          style={
            this.state.createOU.display || this.state.editOU.display
              ? BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div style={userTableWrapper}>
            <OperatingUnitListTable
              data={this.props.OUData}
              handleCreateOU={this.handleCreateOU}
              handleEditOU={this.handleEditOU}
              handleDeleteOU={this.props.handleDeleteOU}
            />
          </div>
          <div style={{ height: "40px" }} />
        </div>
        {this.state.createOU.display && (
          <OperatingUnitCreationPopUp
            cancelFunction={this.handleCreateOUCancel}
            submitFunction={this.props.handleCreateOU}
          />
        )}
        {this.state.editOU.display && (
          <OperatingUnitEditPopUp
            data={this.state.editOU.data}
            cancelFunction={this.handleEditOUCancel}
            submitFunction={this.props.handleEditOU}
          />
        )}
      </div>
    );
  }

  handleCreateOU = () => {
    this.setState({ createOU: { display: true } });
  };
  handleCreateOUCancel = () => {
    this.setState({ createOU: { display: false } });
  };
  handleEditOU = (data) => {
    this.setState({ editOU: { display: true, data: data } });
  };
  handleEditOUCancel = () => {
    this.setState({ editOU: { display: false } });
  };
}

const userTableWrapper = {
  marginLeft: "15.6rem",
  marginRight: "15.6rem",
  marginTop: "3.9rem",
  height: "fit-content",
  width: "1420px",
  backgroundColor: "rgba(0,0,0,0.4)",
};

const BlurEffectStyle = {
  filter: "blur(5px)",
};

export { ManageOUPage };
