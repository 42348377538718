import React, { useCallback } from "react";
import { AdminDashboard } from "./page/component/adminDashboard";
import {
  fetchAdminAPIWithPermission,
  requestToken,
  sendEmailAPIWithPermission,
} from "@aim-mf/api";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";
import { useHistory } from "react-router-dom";
import { TEMPLATE_USER_CREATION } from "./emailTemplate";

import "./app.scss";
import "bootstrap/dist/css/bootstrap.css";

const initialState = {
  loadingProfileList: true,
  loadingUserList: true,
  loadingRoleList: false,
  loadingTitleList: true,
  loadingOUList: true,
  loadingUserRole: false,
  loadingAccessLevel: true,
  profileList: [],
  accessLevel: {},
  permissionList: [],
  userList: [],
  roleList: [],
  titleList: [],
  OUList: [],
  log: [],
  userRole: [],
  ou_id: JSON.parse(sessionStorage.getItem("CurrentUser")).current_ou,
  pageNav: window.location.href.toString().split(window.location.host)[1],
  userEditRole: [],
  roleQuery: {},
  currentUser: {},
};

function App(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    loadingProfileList,
    loadingUserList,
    loadingRoleList,
    loadingTitleList,
    loadingOUList,
    loadingUserRole,
    loadingAccessLevel,
    profileList,
    permissionList,
    log,
    accessLevel,
    userList,
    roleList,
    titleList,
    OUList,
    userRole,
    ou_id,
    pageNav,
    userEditRole,
    roleQuery,
    currentUser,
  } = state;

  const history = useHistory();
  React.useEffect(() => {
    dispatch({ type: "changePageNav", data: location.pathname });
    dispatch({
      type: "loadCurrentUserInfo",
      data: {
        ...JSON.parse(sessionStorage.getItem("CurrentUser")),
        ...JSON.parse(sessionStorage.getItem("MyProfile")),
      },
    });
    if (loadingProfileList) {
      //console.log("load ProfileList");
      const profileSubscription = fetchAdminAPIWithPermission(
        "auth/profile/list"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadProfileList", results });
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            loadProfileList();
          });
        }
      );
      return () => profileSubscription.unsubscribe();
    }

    if (loadingAccessLevel) {
      //console.log("load accesslevel");
      const subscription = fetchAdminAPIWithPermission(
        "auth/profile/access_level"
      ).subscribe(
        (results) => {
          ////console.log("in post api results");
          dispatch({ type: "loadAccessLevel", data: results.data });
        },
        (err) => {
          dispatch({ type: "notLoadingAccessLevel" });
          //console.log(err); // eslint-disable-line
          // //console.log(err.response.data); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleAccessLevelRequest();
          });
        }
      );

      return () => subscription.unsubscribe();
    }

    if (loadingOUList) {
      //console.log("load ou list");
      const subscription = fetchAdminAPIWithPermission(
        "auth/ou/list"
      ).subscribe(
        (results) => {
          ////console.log("in post api results");
          dispatch({ type: "loadOUList", data: results.data });
          dispatch({ type: "setOUID", data: results.data[0].ou_id });
          handleRoleListRequest();
        },
        (err) => {
          dispatch({ type: "notLoadingOUList" });
          //console.log(err); // eslint-disable-line
          // //console.log(err.response.data); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleOUListRequest();
          });
        }
      );

      return () => subscription.unsubscribe();
    }
    if (loadingUserList) {
      //console.log("load userList");
      const subscription = fetchAdminAPIWithPermission(
        "auth/user/list"
      ).subscribe(
        (results) => {
          ////console.log("in post api results");
          dispatch({ type: "loadUserList", data: results.data });
        },
        (err) => {
          dispatch({ type: "notLoadingUserList" });
          //console.log(err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleUserListRequest();
          });
          // //console.log(err.response.data); // eslint-disable-line
        }
      );

      return () => subscription.unsubscribe();
    }
    if (loadingTitleList) {
      //console.log("load title List");
      const subscription = fetchAdminAPIWithPermission(
        "auth/title/list"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadTitleList", data: results.data });
        },
        (err) => {
          dispatch({ type: "notLoadingTitleList" });
          //console.log(err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleTitleListRequest();
          });
          // //console.log(err.response.data); // eslint-disable-line
        }
      );

      return () => subscription.unsubscribe();
    }
    if (loadingRoleList) {
      //console.log("load role List");
      const subscription = fetchAdminAPIWithPermission("auth/role/list", {
        data: { ou_id: ou_id },
      }).subscribe(
        (results) => {
          dispatch({ type: "notLoadingRoleList" });
          ////console.log("in post api results");
          dispatch({ type: "loadRoleList", data: results.data });
        },
        (err) => {
          dispatch({ type: "notLoadingRoleList" });
          //console.log(err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleRoleListRequest();
          });
          // //console.log(err.response.data); // eslint-disable-line
        }
      );

      return () => subscription.unsubscribe();
    }
    if (loadingUserRole) {
      //console.log("load userRole");
      const subscription = fetchAdminAPIWithPermission("auth/user/role", {
        data: {
          ou_id: ou_id,
          username: JSON.parse(sessionStorage.getItem("MyProfile")).username,
        },
      }).subscribe(
        (results) => {
          dispatch({ type: "notLoadingUserRole" });
          ////console.log("in post api results");
          dispatch({ type: "loadUserRole", data: results.data });
        },
        (err) => {
          dispatch({ type: "notLoadingUserRole" });
          //console.log(err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            handleUserRoleRequest();
          });
          // //console.log(err.response.data); // eslint-disable-line
        }
      );

      return () => subscription.unsubscribe();
    }
    history.listen((location) => {
      ////console.log(location.pathname);
      dispatch({ type: "changePageNav", data: location.pathname });
    });
  }, [
    loadingUserList,
    loadingRoleList,
    loadingTitleList,
    loadingOUList,
    loadingUserRole,
    history,
    ou_id,
    loadingAccessLevel,
    loadingProfileList,
    loadProfileList,
  ]);

  const loadProfileList = useCallback(() => {
    const profileSubscription = fetchAdminAPIWithPermission(
      "auth/profile/list"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadProfileList", results });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          loadProfileList();
        });
      }
    );
    return () => profileSubscription.unsubscribe();
  });
  const handleAccessLevelRequest = () => {
    dispatch({ type: "loadingAccessLevel" });
  };
  const handleOUListRequest = () => {
    dispatch({ type: "loadingOUList" });
  };

  const handleUserListRequest = () => {
    dispatch({ type: "loadingUserList" });
  };
  const handleRoleListRequest = () => {
    dispatch({ type: "loadingRoleList" });
  };
  const handleTitleListRequest = () => {
    dispatch({ type: "loadingTitleList" });
  };
  const handleUserRoleRequest = () => {
    dispatch({ type: "loadingUserRole" });
  };

  const sendEmail = (data) => {
    const subscription = sendEmailAPIWithPermission("notification/email", {
      data: data,
    }).subscribe(
      (results) => {},
      (err) => {
          console.log(err); // eslint-disable-line
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let data = {
            callbackFunction: () => {
              sendEmail(data);
            },
          };
          requestToken(data);
        }
      }
    );
    return () => subscription.unsubscribe();
  };

  const testSendEmail = (data) => {
    const subscription = sendEmailAPIWithPermission("notification/email", {
      data: data,
    }).subscribe(
      (results) => {},
      (err) => {
          console.log(err); // eslint-disable-line
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let data = {
            callbackFunction: () => {
              sendEmail(data);
            },
          };
          requestToken(data);
        }
      }
    );
    return () => subscription.unsubscribe();
  };

  const handleRoleQuery = (data) => {
    const subscription = fetchAdminAPIWithPermission("auth/role/query", {
      data: data,
    }).subscribe(
      (results) => {
        dispatch({ type: "loadRoleQuery", data: results.data });
      },
      (err) => {
          console.log(err); // eslint-disable-line
        // //console.log(err.response.data); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleRoleQuery(data);
        });
      }
    );
    return () => subscription.unsubscribe();
  };

  const handleUpdateUser = (data, addedRoles, revokedRoles) => {
    if (data.password === "") {
      delete data.password;
    }
    if (data.title_id === "") {
      delete data.title_id;
    }
    ////console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/user/edit", {
      data: data,
    }).subscribe(
      (results) => {
        //console.log(roles);
        addedRoles.map((role) => {
          handleUserAssignRole({
            username: data.username,
            role_id: role.role_id,
            ou_id: role.ou_id,
          });
        });
        revokedRoles.map((role) => {
          handleUserRevokeRole({
            username: data.username,
            role_id: role.role_id,
            ou_id: role.ou_id,
          });
        });
        handleUserListRequest();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let data = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleUpdateUser(data);
            },
          };
          requestToken(data);
        }
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleUpdateUserQueryUserRole = (data) => {
    const subscription = fetchAdminAPIWithPermission("auth/user/role", {
      data: data,
    }).subscribe(
      (results) => {
        //console.log(results)
        dispatch({ type: "loadUserEditRole", data: results.data });
      },
      (err) => {
        dispatch({ type: "notLoadingUserRole" });
        //console.log(err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleUserRoleRequest();
        });
        // //console.log(err.response.data); // eslint-disable-line
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleCreateOU = (payload) => {
    let data = payload;
    ////console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/ou/create", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleOUListRequest();
      },
      (err) => {
        ////console.log(err); // eslint-disable-line
        ////console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleCreateOU(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleDeleteOU = (payload) => {
    let data = payload;
    //console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/ou/delete", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleOUListRequest();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        //console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleDeleteOU(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleEditOU = (payload) => {
    let data = payload;
    ////console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/ou/edit", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleOUListRequest();
      },
      (err) => {
        ////console.log(err); // eslint-disable-line
        ////console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleEditOU(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleCreateTitle = (payload) => {
    let data = payload;
    ////console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/title/create", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleTitleListRequest();
      },
      (err) => {
        ////console.log(err); // eslint-disable-line
        ////console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleCreateTitle(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleDeleteTitle = (payload) => {
    let data = payload;
    ////console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/title/delete", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleTitleListRequest();
      },
      (err) => {
        ////console.log(err); // eslint-disable-line
        ////console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleDeleteTitle(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );
    return () => subscription.unsubscribe();
  };

  const handleEditTitle = (payload) => {
    let data = payload;
    //console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/title/edit", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleTitleListRequest();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        //console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleEditTitle(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );

    return () => subscription.unsubscribe();
  };
  const handlePermissionListQuery = (payload) => {
    let data = payload;
    //console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/permission/list", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        dispatch({ type: "loadPermissionList", data: results.data });
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        //console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleCreateRole(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleCreateRole = (payload) => {
    let data = payload;
    //console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/role/create", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleRoleListRequest();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        //console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleCreateRole(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleDeleteRole = (payload) => {
    let data = payload;
    //console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/role/delete", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleRoleListRequest();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        //console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleDeleteRole(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );
    return () => subscription.unsubscribe();
  };

  const handleEditRole = (payload) => {
    let data = payload;
    //console.log(data);
    const subscription = fetchAdminAPIWithPermission("auth/role/edit", {
      data: data,
    }).subscribe(
      (results) => {
        ////console.log("in post api results");
        handleRoleListRequest();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        //console.log(data);
        // //console.log(err.response.data); // eslint-disable-line
        if (err.response.data.detail === "Token expired") {
          let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
          let tokenPayLoadData = {
            ou: currentUser.current_ou,
            tenant: currentUser.tenant,
            callbackFunction: () => {
              handleEditRole(data);
            },
          };
          requestToken(tokenPayLoadData);
        }
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleCreateUser = (userData, roleData) => {
    const subscription = fetchAdminAPIWithPermission("auth/user/create", {
      data: userData,
    }).subscribe(
      (results) => {
        handleUserAssignRole({
          username: userData.username,
          role_id: roleData[0].role_id,
          ou_id: roleData[0].ou_id,
        });
        sendEmail(
          TEMPLATE_USER_CREATION(
            userData.username,
            userData.password,
            userData.display_name,
            userData.email
          )
        );
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleCreateUser(userData, roleData);
        });
        // //console.log(err.response.data); // eslint-disable-line
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleUserAssignRole = (roleData) => {
    const assignRoleSubscription = fetchAdminAPIWithPermission(
      "auth/role/assign",
      { data: roleData }
    ).subscribe(
      (results) => {
        handleUserListRequest();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        // AuthCheckAndAutofix(err, ()=>{handleCreateUser(data)});
        // //console.log(err.response.data); // eslint-disable-line
      }
    );
    return () => assignRoleSubscription.unsubscribe();
  };

  const handleUserRevokeRole = (roleData) => {
    const assignRoleSubscription = fetchAdminAPIWithPermission(
      "auth/role/revoke",
      { data: roleData }
    ).subscribe(
      (results) => {
        handleUserListRequest();
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        // AuthCheckAndAutofix(err, ()=>{handleCreateUser(data)});
        // //console.log(err.response.data); // eslint-disable-line
      }
    );
    return () => assignRoleSubscription.unsubscribe();
  };
  const handleLogRequest = (data) => {
    const assignRoleSubscription = fetchAdminAPIWithPermission("auth/log", {
      data: data,
    }).subscribe(
      (results) => {
        dispatch({ type: "loadLogs", data: results.data });
      },
      (err) => {
        //console.log(err); // eslint-disable-line
        // AuthCheckAndAutofix(err, ()=>{handleCreateUser(data)});
        // //console.log(err.response.data); // eslint-disable-line
      }
    );
    return () => assignRoleSubscription.unsubscribe();
  };

  const handleRoleRequestOUChange = (OUname) => {
    //console.log(OUname);
    //console.log(loadingRoleList);
    let ou_id = OUList.filter((o) => {
      return o.display_name === OUname;
    })[0].ou_id;
    dispatch({ type: "setOUID", data: ou_id });
    handleRoleListRequest();
  };

  const handleUserRoleRequestOUChange = (OUname) => {
    //console.log(OUname);
    //console.log(loadingRoleList);
    let ou_id = OUList.filter((o) => {
      return o.display_name === OUname;
    })[0].ou_id;
    dispatch({ type: "setOUID", data: ou_id });
    handleUserRoleRequest();
  };

  return (
    <div>
      <AdminDashboard
        profileList={profileList}
        aimBaseUrl={props.aimBaseUrl}
        pageNav={pageNav}
        userList={userList}
        titleList={titleList}
        roleList={roleList}
        OUList={OUList}
        userRole={userRole}
        accessLevel={accessLevel}
        log={log}
        userEditRole={userEditRole}
        roleQuery={roleQuery}
        currentUser={currentUser}
        handleUpdateUserQueryUserRole={handleUpdateUserQueryUserRole}
        permissionList={permissionList}
        handleCreateOU={handleCreateOU}
        handleDeleteOU={handleDeleteOU}
        handleEditOU={handleEditOU}
        handleCreateTitle={handleCreateTitle}
        handleDeleteTitle={handleDeleteTitle}
        handleEditTitle={handleEditTitle}
        handleCreateRole={handleCreateRole}
        handleDeleteRole={handleDeleteRole}
        handleEditRole={handleEditRole}
        handleUserListRequest={handleUserListRequest}
        andleRoleListRequest={handleRoleListRequest}
        handleTitleListRequest={handleTitleListRequest}
        handleUpdateUser={handleUpdateUser}
        handleRoleRequestOUChange={handleRoleRequestOUChange}
        handleUserRoleRequestOUChange={handleUserRoleRequestOUChange}
        handleCreateUser={handleCreateUser}
        handleLogRequest={handleLogRequest}
        handlePermissionListQuery={handlePermissionListQuery}
        testSendEmail={testSendEmail}
        handleRoleQuery={handleRoleQuery}
      />
    </div>
  );

  function reducer(state = initialState, action) {
    switch (action.type) {
      case "loadCurrentUserInfo":
        return {
          ...state,
          currentUser: action.data,
        };
      case "changePageNav":
        return { ...state, pageNav: action.data };
      case "loadingProfileList":
        return { ...state, loadingProfileList: true };
      case "loadingAccessLevel":
        return { ...state, loadingAccessLevel: true };
      case "loadingUserList":
        return { ...state, loadingUserList: true };
      case "loadingRoleList":
        return { ...state, loadingRoleList: true };
      case "loadingTitleList":
        return { ...state, loadingTitleList: true };
      case "loadingOUList":
        return { ...state, loadingOUList: true };
      case "loadingUserRole":
        return { ...state, loadingUserRole: true };
      case "notLoadingAccessLevel":
        return { ...state, loadingAccessLevel: false };
      case "notLoadingUserList":
        return { ...state, loadingUserList: false };
      case "notLoadingRoleList":
        return { ...state, loadingRoleList: false };
      case "notLoadingTitleList":
        return { ...state, loadingTitleList: false };
      case "notLoadingOUList":
        return { ...state, loadingOUList: false };
      case "notLoadingUserRole":
        return { ...state, loadingUserRole: false };
      case "loadProfileList":
        return {
          ...state,
          profileList: action.results.data,
          loadingProfileList: false,
        };
      case "setOUID":
        return { ...state, ou_id: action.data };
      case "loadRoleQuery":
        return {
          ...state,
          roleQuery: action.data,
        };
      case "loadAccessLevel":
        return {
          ...state,
          accessLevel: action.data,
          loadingAccessLevel: false,
        };
      case "loadUserList":
        //console.log(action.data);
        let me = JSON.parse(sessionStorage.getItem("MyProfile"));
        let myAccessLevel = me.access_level;
        let userList = action.data.filter((data) => {
          return data.access_level > myAccessLevel;
        });
        return {
          ...state,
          userList: userList,
          loadingUserList: false,
        };
      case "loadRoleList":
        return {
          ...state,
          roleList: action.data,
          loadingRoleList: false,
        };
      case "loadPermissionList":
        return {
          ...state,
          permissionList: action.data,
        };
      case "loadOUList":
        return {
          ...state,
          OUList: action.data,
          loadingOUList: false,
        };
      case "loadUserEditRole":
        return {
          ...state,
          userEditRole: action.data,
        };
      case "loadLogs":
        return {
          ...state,
          log: action.data,
        };
      case "loadUserRole":
        return {
          ...state,
          userRole: action.data,
          loadingUserRole: false,
        };
      case "loadTitleList":
        return {
          ...state,
          titleList: action.data,
          loadingTitleList: false,
        };
      default:
        throw Error(`Unknown action type '${action.type}'`);
    }
  }
}

export default App;
