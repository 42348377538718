import React from "react";
import {
  GridColumnMenuCheckboxFilter,
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { orderBy, process } from "@progress/kendo-data-query";
import {
  IconButtonSolid,
  ButtonSolid,
  SearchBar,
  Pill,
  TooltipTemplate,
  FormatDate,
  IconSVG,
  mapping,
  CustomDatePicker,
  JSDateTimeToDBDateTime,
} from "@aim-mf/styleguide";

// <RiskTable
//     data={this.props.riskList}
//     addNew={this.createRiskHandler}
//     archive={this.props.handleArchiveRisk}
//     rights={this.props.rights}
// />

let data = {
  username: "string",
  email: "user@example.com",
  display_name: "string",
  title: "string",
  access_level: 0,
  is_active: true,
  is_sso: true,
  is_pwd_change_required: true,
  password_date: "2020-08-04T02:04:32.333Z",
  last_login: "2020-08-04T02:04:32.333Z",
  last_modified: "2020-08-04T02:04:32.333Z",
  last_modified_by: "string",
};

class ColumnMenuCheckboxFilter extends React.Component {
  render() {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...this.props}
          data={this.props.sort[0].data}
          expanded={true}
        />
      </div>
    );
  }
}

class LogsTable extends React.Component {
  constructor(props) {
    super(props);
    let dataWithSelection = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let today = new Date(new Date().setHours(23, 59, 59, 999));
    let lastMonth = new Date(new Date().setHours(0, 0, 0, 0));
    lastMonth = lastMonth.setMonth(today.getMonth() - 1);
    this.state = {
      sort: [{ field: "log_time", dir: "asc", data: this.props.data }],
      result: dataWithSelection,
      filterData: dataWithSelection,
      searchData: dataWithSelection,
      dataState: "",
      editUser: { display: false },
      endingDate: today,
      startingDate: lastMonth,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let dataWithSelection = nextProps.data.map((dataItem) =>
        Object.assign({ selected: false }, dataItem)
      );
      this.setState({
        sort: [{ field: "log_time", dir: "asc", data: nextProps.data }],
        result: dataWithSelection,
        filterData: dataWithSelection,
        searchData: dataWithSelection,
        dataState: "",
      });
    }
  }

  render() {
    //console.log(this.props.OUList);
    //console.log(this.state.result);

    // reformat result cater for the display requirement
    let displayResult = this.state.result;
    displayResult = displayResult.map((a) => {
      let log_time = FormatDate(a.timestamp, "MM/DD/YYYY HH:MM:SS");
      let ou_name;
      if (a.ou_id !== null) {
        ou_name = this.props.OUList.filter((ou) => {
          return ou.ou_id === a.ou_id;
        })[0].display_name;
      } else {
        ou_name = "";
      }
      return { ...a, log_time: log_time, ou_name: ou_name };
    });

    return (
      <div style={userTableWrapper}>
        <div style={tableTitlePadding}>
          <div style={titleStyle}>Audit Logs View</div>
          <div className="risk-card-subtitle">
            This Table lists the audit logs
          </div>
        </div>
        <div
          id="table-tool-bar"
          style={{
            margin: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
          className="mb-1"
        >
          <div
            id={"time-filter"}
            style={{
              width: "400px",
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CustomDatePicker
              value={this.state.startingDate}
              CustomOnChange={(value) => {
                //console.log(JSDateTimeToDBDateTime(value));
                this.setState({ startingDate: JSDateTimeToDBDateTime(value) });
              }}
            />
            <div style={{ ...mapping["forms/label/1-default"], padding: 0 }}>
              to
            </div>
            <CustomDatePicker
              value={this.state.endingDate}
              CustomOnChange={(value) => {
                //console.log(JSDateTimeToDBDateTime(value));
                this.setState({ endingDate: JSDateTimeToDBDateTime(value) });
              }}
            />
            <div
              className={"div_hover"}
              style={{
                width: "2rem",
                height: "2rem",
                backgroundColor: mapping["Color_Basic_Primary"],
              }}
            >
              <IconSVG
                wrapperStyle={{ width: "100%", height: "100%" }}
                name={"search"}
                color={"white"}
                onClick={() => {
                  this.props.handleLogRequest({
                    start_time: this.state.startingDate,
                    end_time: this.state.endingDate,
                    //username: JSON.parse(sessionStorage.getItem("MyProfile")).username,
                  });
                }}
              />
            </div>
          </div>
          <div className="mx-1">
            <SearchBar
              searchCallBack={this.onSearchCallBack}
              hint={"Search by user name..."}
            />
          </div>
        </div>

        <Grid
          style={{ maxHeight: "500px", height: "fit-content" }}
          {...this.state.dataState}
          data={this.customOrderBy(displayResult, this.state.sort)}
          sortable
          onDataStateChange={this.dataStateChange}
          onRowClick={this.rowClick}
          sort={this.state.sort}
          rowRender={this.rowRender}
          onSortChange={(e) => {
            var sortConfig = e.sort[0];
            if (!sortConfig) {
              this.setState({
                sort: [{ data: this.props.data }],
              });
            } else if (sortConfig.field === "formatDateTime") {
              sortConfig = { ...sortConfig, data: this.props.data };
              this.setState({
                sort: [sortConfig],
              });
            }
          }}
          customizedProps={"123"}
        >
          <Column field="log_name" title="Log Name" width="200px" />
          <Column field="log_message" title="Log Message" width="500px" />
          <Column field="log_level" title="Log Type" />
          <Column field="username" title="User Name" />
          <Column field="ou_name" title="Business Entity" width="150px" />
          <Column field="tenant" title="Domain" />
          <Column field="log_time" title="Log Time" width="250px" />
        </Grid>
      </div>
    );
  }

  // search related
  onSearchCallBack = (e) => {
    let searchContent = e.target.value;

    let resultData = this.state.filterData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let searchData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );

    if (!searchContent) {
      this.setState({ result: resultData, searchData: searchData });
      return;
    }
    // find the user that suits the search keywords (by display name)
    let userOnSearch = this.state.filterData.filter((userItem) => {
      let matchDisplayName = false;
      if (userItem.display_name) {
        matchDisplayName = userItem.display_name
          .toLowerCase()
          .includes(searchContent.toLowerCase());
      }
      return matchDisplayName;
    });
    this.setState({ searchData: userOnSearch, result: userOnSearch });
  };

  // filter related
  createDataState = (dataState) => {
    let resultData = this.state.searchData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let filterData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    return {
      result: process(resultData, dataState).data,
      filterData: process(filterData, dataState).data,
      dataState: dataState,
    };
  };

  dataStateChange = (event) => {
    let dataState = event.data;
    dataState.take = 100;
    this.setState(this.createDataState(dataState));
  };

  // sorting function
  customOrderBy = (data, sort) => {
    let result = [];

    let sortedData = orderBy(data, sort);

    while (sortedData.length !== 0) {
      //console.log(sortedData);
      if (sortedData[0].isParentRisk) {
        let parentID = sortedData[0].sourceRiskID;
        result = result.concat(sortedData[0]);
        sortedData = sortedData.filter((risk) => {
          return risk.sourceRiskID != parentID;
        });
        let subRisk = sortedData.filter((risk) => {
          return risk.parentRisk_id == parentID;
        });
        result = result.concat(subRisk);
        sortedData = sortedData.filter((risk) => {
          return risk.parentRisk_id != parentID;
        });
      } else {
        let parentID = sortedData[0].parentRisk_id;
        let parentRiskSort = sortedData.filter((risk) => {
          return risk.sourceRiskID == parentID;
        });
        result = result.concat(parentRiskSort);
        sortedData = sortedData.filter((risk) => {
          return risk.sourceRiskID != parentID;
        });
        let subRisk = sortedData.filter((risk) => {
          return risk.parentRisk_id === parentID;
        });
        result = result.concat(subRisk);
        sortedData = sortedData.filter((risk) => {
          return risk.parentRisk_id !== parentID;
        });
      }

      //console.log(sortedSubRisk);
      //console.log(result);
    }

    //console.log("sort function");

    //console.log(result);

    return result;
  };

  //archive function
  archiveUser = () => {
    // get all risk with selected
    let selectedUser = this.state.result.filter((user) => {
      return user.selected;
    });
    let archiveUserID = selectedUser.map((a) => a.username);
    this.props.archive(archiveUserID);
  };

  rowRender(trElement, props) {
    const parentRow = { backgroundColor: "#00000066" };
    const subRiskRow = { backgroundColor: "#00000000" };
    const trProps = {
      style: props.dataItem.isParentRisk ? parentRow : subRiskRow,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }
}

const userTableWrapper = {
  marginLeft: "15.6rem",
  marginRight: "15.6rem",
  marginTop: "3.9rem",
  height: "fit-content",
  width: "fit-content",
  backgroundColor: "rgba(0,0,0,0.4)",
};
const tableTitlePadding = {
  paddingLeft: "1rem",
  paddingTop: "1rem",
};

const titleStyle = {
  marginBottom: "1rem",
  ...mapping["heading/h5/lightleft"],
};

const BlurEffectStyle = {
  filter: "blur(5px)",
};
export { LogsTable };
