import React from "react";
import { mapping, NonValidationInput, ButtonSolid } from "@aim-mf/styleguide";
import { TitleListTable } from "./title/titleListTable";
import { RoleListTable } from "../manageRole/roleListTable";
import { OperatingUnitListTable } from "../operatingUnit/OU/operatingUnitListTable";
import { OperatingUnitCreationPopUp } from "../operatingUnit/OU/operatingUnitCreationPopUp";
import { OperatingUnitEditPopUp } from "../operatingUnit/OU/operatingUnitEditPopUp";
import { TitleCreationPopUp } from "./title/titleCreationPopUp";
import { TitleEditPopUp } from "./title/titleEditPopUp";

// this part i think can use AutoComplete from styleguide

class ManageTitlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ou_name: "",
      createOU: { display: false },
      createRole: { display: false },
      createTitle: { display: false },
      editOU: { display: false },
      editRole: { display: false },
      editTitle: { display: false },
    };
  }

  render() {
    return (
      <div>
        <div
          style={
            this.state.createTitle.display || this.state.editTitle.display
              ? BlurEffectStyle
              : { dummy: "dummy" }
          }
        >
          <div style={{ height: "20px" }} />
          <div style={userTableWrapper}>
            <TitleListTable
              data={this.props.titleData}
              handleCreateTitle={this.handleCreateTitle}
              handleDeleteTitle={this.props.handleDeleteTitle}
              handleEditTitle={this.handleEditTitle}
            />
          </div>
          <div style={{ height: "40px" }} />
        </div>
        {this.state.createTitle.display && (
          <TitleCreationPopUp
            cancelFunction={this.handleCreateTitleCancel}
            submitFunction={this.props.handleCreateTitle}
          />
        )}
        {this.state.editTitle.display && (
          <TitleEditPopUp
            data={this.state.editTitle.data}
            cancelFunction={this.handleEditTitleCancel}
            submitFunction={this.props.handleEditTitle}
          />
        )}
      </div>
    );
  }

  handleCreateTitle = () => {
    this.setState({ createTitle: { display: true } });
  };
  handleCreateTitleCancel = () => {
    this.setState({ createTitle: { display: false } });
  };
  handleEditTitle = (data) => {
    this.setState({ editTitle: { display: true, data: data } });
  };
  handleEditTitleCancel = () => {
    this.setState({ editTitle: { display: false } });
  };
}

const userTableWrapper = {
  marginLeft: "15.6rem",
  marginRight: "15.6rem",
  marginTop: "3.9rem",
  height: "fit-content",
  width: "1420px",
  backgroundColor: "rgba(0,0,0,0.4)",
};

const BlurEffectStyle = {
  filter: "blur(5px)",
};

export { ManageTitlePage };
