import React from "react";
import {
  mapping,
  Tab,
  CustomizedCheckBox,
  ButtonSolid,
  DropDownListButton,
} from "@aim-mf/styleguide";
import { CreateUser } from "./createUser";

// this part you only need to list down some of the check box (the content will change later)
const Roles = [
  "View Dashboard",
  "Change own password",
  "Create, Edit, & Delete Risks",
  "Assign Risk Owner",
];

class UserRoleAssign extends React.Component {
  state = {
    roles: this.props.roleList,
    assignedRoles: [],
    tabs: "Add Rule",
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      JSON.stringify(nextProps.roleList) !== JSON.stringify(this.props.roleList)
    ) {
      this.setState({ roles: nextProps.roleList });
    }
  }

  handleCheckboxChange = (roleClicked) => {
    //console.log(role);
    let assignedRoles = this.state.assignedRoles;
    if (assignedRoles.map((a) => a.role_id).includes(roleClicked.role_id)) {
      assignedRoles = assignedRoles.filter((role) => {
        return role.role_id !== roleClicked.role_id;
      });
    } else {
      assignedRoles.push(roleClicked);
    }
    //console.log(assignedRoles);
    this.setState({ assignedRoles: assignedRoles });
  };

  handleTabChange = () => {
    // this.setState({ tabs: newName });
    // console.log(name);
  };
  render() {
    //console.log(this.props.roleList);
    return (
      <div style={{ width: "940px", margin: "auto" }}>
        <div style={userRoleAssignStyle}>
          <div style={tabStyle}>
            <Tab
              OnTabChange={this.handleTabChange}
              tabWidth={"139px"}
              height={"48px"}
              labels={["Add Role"]}
              activeBackgroundColor={"#182237"}
            />
          </div>
          <DropDownListButton
            title={"Select a business entity"}
            data={this.props.OUList.map((ou) => ou.display_name)}
            value={this.props.OUList.map((ou) => ou.display_name)[0]}
            DropDownButtonStyle={OUDropDownButtonStyle}
            CustomOnChange={(event) => {
              this.props.handleRoleRequestOUChange(event.value);
              this.props.handleUserRoleRequestOUChange(event.value);
            }}
          />
          <div style={{ height: "20px" }} />
          <RoleAssignComponent
            roles={this.state.roles}
            assignedRoles={this.state.assignedRoles}
            handleCheckboxChange={this.handleCheckboxChange}
          />
        </div>

        <div style={buttonPosition}>
          <ButtonSolid name={"Cancel"} color={"Secondary"} />

          <div style={rightButtonPosition}>
            <ButtonSolid
              name={"Add User"}
              width={"80px"}
              clickEvent={() => {
                this.props.handleAddUser(this.state.assignedRoles);
              }}
            />
            <ButtonSolid name={"Back"} clickEvent={this.props.handlePrevious} />
          </div>
        </div>
      </div>
    );
  }
}

const RoleAssignComponent = (props) => {
  return (
    <div style={{ color: "white" }}>
      {props.roles.map((role) => {
        return (
          <CustomizedCheckBox
            name={role.display_name}
            label={role.display_name}
            value={props.assignedRoles
              .map((a) => a.display_name)
              .includes(role.display_name)}
            onCheckChange={() => {
              props.handleCheckboxChange(role);
            }}
            checkBoxWrapperStyle={checkBoxWrapperStyle}
            key={role}
          />
        );
      })}
    </div>
  );
};

const userRoleAssignStyle = {
  margin: "1.5rem auto",
  textAlign: "left",
  color: "white",
  backgroundColor: "#182237",
  padding: "0.5rem",
};
const tabStyle = {
  fontSize: 12,
  marginBottom: "1.5rem",
};
const checkBoxWrapperStyle = {
  marginLeft: "0.5rem",
  marginBottom: "0.5rem",
};
const buttonPosition = {
  display: "inline-flex",
  justifyContent: "space-between",
  width: "940px",
  margin: "1.5rem auto",
};
const rightButtonPosition = {
  width: "160px",
  display: "inline-flex",
  justifyContent: "space-between",
};

const OUDropDownButtonStyle = {
  width: "190px",
  height: "32px",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};

export { UserRoleAssign, RoleAssignComponent };
