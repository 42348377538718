let system = "AIM";
let signinature = "This is a system generated message. Please do not reply.";

export let TEMPLATE_USER_CREATION = (
  username,
  password,
  display_name,
  recipients
) => {
  return {
    subject: "[" + system + "] your account has been created",
    body:
      "Hi " +
      display_name +
      ", \n\n" +
      "Your account has been created successfully\n" +
      "You can login at http://test.aim.org/login with following information\n\n" +
      "username: " +
      username +
      "\n" +
      "password: " +
      password +
      "\n\n" +
      signinature,
    recipients: Array.isArray(recipients) ? recipients : [recipients],
  };
};
