import React from "react";
import {
  mapping,
  NameCard,
  ButtonSolid,
  NonValidationInput,
  CustomizedCheckBox,
  DropDownListButton,
} from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class UserProfilePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: this.props.data,
      assignedRoles: this.props.userEditRole,
      addedRoles: [],
      revokedRoles: [],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.userEditRole);
    //console.log(this.props.userEditRole);
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ userData: nextProps.data });
    }
    if (
      JSON.stringify(nextProps.userEditRole) !==
      JSON.stringify(this.props.userEditRole)
    ) {
      this.setState({ assignedRoles: nextProps.userEditRole });
    }
  }

  render() {
    //console.log(this.props.roleList);
    if (Object.keys(this.state.userData) === 0) {
      return <div />;
    }
    return (
      <div style={popupFormWrapStyle}>
        <div style={popupFormStyle}>
          <div style={PopupStyle}>
            <div style={FormTitleStyle}>Edit User Information</div>
            <div>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  placeContent: "center",
                }}
              >
                <NameCard
                  initial={this.state.userData.display_name
                    .split(" ")
                    .map((a) => {
                      if (a[0] !== undefined) {
                        return a[0].toUpperCase();
                      }
                    })
                    .join("")}
                  name={this.state.userData.display_name}
                  title={
                    this.state.userData.title
                      ? this.state.userData.title
                      : "Null"
                  }
                />
              </div>
              <div style={filedPadding} />
              <div id={"edit-field"}>
                <NonValidationInput
                  title={"Username (for display)"}
                  value={this.state.userData.display_name}
                  width={"100%"}
                  onChange={this.handleDisplayNameChange}
                />
                <div style={filedPadding} />
                <NonValidationInput
                  title={"Password"}
                  value={this.state.userData.password}
                  width={"100%"}
                  onChange={this.handlePasswordChange}
                />
                <div style={filedPadding} />
                <NonValidationInput
                  title={"Email"}
                  value={this.state.userData.email}
                  width={"100%"}
                  onChange={this.handleEmailChange}
                />
                <div style={filedPadding} />
                <div style={filedPadding} />
                <DropDownListButton
                  title={"Title"}
                  DropDownButtonStyle={titleDropDownButtonStyle}
                  data={this.props.titleList.map((a) => a.display_name)}
                  value={this.state.userData.title}
                  CustomOnChange={this.handleTitleChange}
                />
                <div style={filedPadding} />
                <DropDownListButton
                  title={"Access Level"}
                  DropDownButtonStyle={titleDropDownButtonStyle}
                  data={Object.values(
                    this.getAccessLevelHigherThanCurrentUser()
                  )}
                  value={
                    this.props.accessLevel[this.state.userData.access_level]
                  }
                  CustomOnChange={this.handleAccessLevelChange}
                />
                <div style={filedPadding} />
                <div>
                  <div style={checkboxStyle}>
                    <div style={fieldTitleStyle}>Active</div>
                    <CustomizedCheckBox
                      onCheckChange={this.handleActiveChange}
                      value={this.state.userData.is_active}
                    />
                  </div>
                </div>
                <div style={filedPadding} />
                <div>
                  <div style={checkboxStyle}>
                    <div style={fieldTitleStyle}>
                      Require to change password
                    </div>
                    <CustomizedCheckBox
                      onCheckChange={this.handleRequirePasswordChangeChange}
                      value={this.state.userData.is_pwd_change_required}
                    />
                  </div>
                </div>
                <div style={filedPadding} />

                <DropDownListButton
                  title={"Select a business entity"}
                  data={this.props.OUList.map((ou) => ou.display_name)}
                  value={this.props.OUList.map((ou) => ou.display_name)[0]}
                  DropDownButtonStyle={OUDropDownButtonStyle}
                  CustomOnChange={(event) => {
                    this.props.handleRoleRequestOUChange(event.value);
                    this.props.handleUpdateUserQueryUserRole({
                      username: this.props.data.username,
                      ou_id: event.value,
                    });
                  }}
                />
                <div style={{ height: "20px" }} />
                {this.props.roleList.length !== 0 && (
                  <RoleEditComponent
                    roles={this.props.roleList}
                    assignedRoles={this.state.assignedRoles}
                    handleCheckboxChange={(role) => {
                      this.handleRoleChange(role);
                    }}
                  />
                )}
              </div>

              <div style={buttonWrapper}>
                <ButtonSolid
                  name={"Cancel"}
                  height={"38px"}
                  width={"120px"}
                  size={"Small"}
                  clickEvent={this.props.cancelFunction}
                />
                <ButtonSolid
                  name={"Submit"}
                  height={"38px"}
                  width={"120px"}
                  size={"Small"}
                  clickEvent={() => {
                    let userDatePayload = JSON.parse(
                      JSON.stringify(this.state.userData)
                    );
                    if (this.state.userData.email === this.props.data.email) {
                      delete userDatePayload.email;
                    }
                    //console.log(this.state.revokedRoles)
                    this.props.handleUpdateUser(
                      userDatePayload,
                      this.state.addedRoles,
                      this.state.revokedRoles
                    );
                    this.setState({ addedRole: [], revokedRoles: [] });
                    this.props.cancelFunction();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getAccessLevelHigherThanCurrentUser = () => {
    let filtered = Object.keys(this.props.accessLevel)
      .filter((lvl) => {
        return lvl > this.props.currentUser.access_level;
      })
      .reduce((obj, key) => {
        obj[key] = this.props.accessLevel[key];
        return obj;
      }, {});
    return filtered;
  };

  handleRoleChange = (role) => {
    //console.log(role);
    let assignedRole = this.state.assignedRoles;
    let addedRole = this.state.addedRoles;
    let revokedRoles = this.state.revokedRoles;
    // remove existing role
    if (
      this.state.assignedRoles
        .map((role) => role.role_id)
        .includes(role.role_id)
    ) {
      // if the role is added just now
      if (
        this.state.addedRoles.map((role) => role.role_id).includes(role.role_id)
      ) {
        addedRole = this.state.addedRoles;
        addedRole = addedRole.filter((addedRole) => {
          return addedRole.role_id !== role.role_id;
        });
        this.setState({ addedRole: addedRole });
      } else {
        // the role is not added just now, need to revoke
        revokedRoles = this.state.revokedRoles;
        revokedRoles.push(role);
      }
      assignedRole = assignedRole.filter((assignedRole) => {
        return assignedRole.role_id !== role.role_id;
      });
    } else {
      if (
        this.state.revokedRoles
          .map((role) => {
            role.role_id;
          })
          .includes(role.role_id)
      ) {
        revokedRoles = this.state.revokedRoles;
        revokedRoles = revokedRoles.filter((revokedRole) => {
          return revokedRole.role_id !== role.role_id;
        });
      }
      addedRole.push(role);
      assignedRole.push(role);
    }
    this.setState({
      assignedRoles: assignedRole,
      addedRole: addedRole,
      revokedRoles: revokedRoles,
    });
  };

  handlePasswordChange = (event) => {
    let userData = this.state.userData;
    userData.password = event.value;
    this.setState({ userData: userData });
  };
  handleEmailChange = (event) => {
    let userData = JSON.parse(JSON.stringify(this.state.userData));
    userData.email = event.value;
    this.setState({ userData: userData });
  };
  handleDisplayNameChange = (event) => {
    let userData = this.state.userData;
    userData.display_name = event.value;
    this.setState({ userData: userData });
  };
  handleTitleChange = (event) => {
    let titleName = event.value;
    let titleID = this.props.titleList.filter((title) => {
      return title.display_name === titleName;
    })[0].id;

    let userData = this.state.userData;
    userData.title = titleName;
    userData.title_id = titleID;
    this.setState({ userData: userData });
  };

  handleAccessLevelChange = (event) => {
    let userData = this.state.userData;
    userData.access_level = Object.keys(this.props.accessLevel).find(
      (key) => this.props.accessLevel[key] === event.value
    );
    this.setState({ userData: userData });
  };

  handleActiveChange = () => {
    let userData = this.state.userData;
    userData.is_active = !userData.is_active;
    this.setState({ userData: userData });
  };
  handleRequirePasswordChangeChange = () => {
    let userData = this.state.userData;
    userData.is_pwd_change_required = !userData.is_pwd_change_required;
    this.setState({ userData: userData });
  };
}
const RoleEditComponent = (props) => {
  return (
    <div style={{ color: "white" }}>
      {props.roles.map((role) => {
        //console.log(props.assignedRoles);
        //console.log(role.display_name);
        return (
          <CustomizedCheckBox
            name={role.display_name}
            label={role.display_name}
            value={props.assignedRoles
              .map((a) => a.role_id)
              .includes(role.role_id)}
            onCheckChange={() => {
              props.handleCheckboxChange(role);
            }}
            checkBoxWrapperStyle={checkBoxWrapperStyle}
            key={role.role_id}
          />
        );
      })}
    </div>
  );
};
const checkboxStyle = {
  display: "inline-flex",
  justifyContent: "space-between",
  width: "100%",
};

const filedPadding = {
  height: "20px",
};

const fieldTitleStyle = {
  ...mapping["Form_Label_Padding"],
  display: "flex",
  paddingBottom: "9px",
  color: mapping["Color_Basic_Light"],
};

const popupFormStyle = {
  position: "relative",
  left: "-50%",
};
const popupFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "13.3rem",
};
const PopupStyle = {
  backgroundColor: "rgba(0,0,0,0.6)",
  padding: "1rem",
  height: "auto",
  width: "36rem",
};

const FormTitleStyle = {
  ...mapping["heading/h5/lightleft"],
  marginBottom: "2rem",
};

const buttonWrapper = {
  display: "inline-flex",
  paddingTop: "4rem",
  width: "100%",
  justifyContent: "space-between",
};

const titleDropDownButtonStyle = {
  width: "100%",
  height: "40px",
  //backgroundColor: mapping["Color_Basic_Secondary"],
  backgroundColor: mapping["Field_BG_Color"],
  border: "none",
  boxShadow: "none",
};
const OUDropDownButtonStyle = {
  width: "190px",
  height: "32px",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const checkBoxWrapperStyle = {
  marginLeft: "0.5rem",
  marginBottom: "0.5rem",
};

export { UserProfilePopup };
