import React from "react";
import App from "./src/App";
import { BrowserRouter } from "react-router-dom";

let aimBaseUrl = "http://test.aim.org/";

export default function Root(props) {
  return (
    <BrowserRouter>
      <div>
        <App aimBaseUrl={aimBaseUrl} />
      </div>
    </BrowserRouter>
  );
}
