import React from "react";
import {
  GridColumnMenuCheckboxFilter,
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { orderBy, process } from "@progress/kendo-data-query";
import {
  IconButtonSolid,
  ButtonSolid,
  SearchBar,
  Pill,
  TooltipTemplate,
  FormatDate,
  IconSVG,
  mapping,
} from "@aim-mf/styleguide";
import { UserProfilePopup } from "./userProfilePopup";

class UserListTable extends React.Component {
  constructor(props) {
    super(props);
    let dataWithSelection = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    this.state = {
      sort: [{ field: "username", dir: "asc", data: this.props.data }],
      result: dataWithSelection,
      filterData: dataWithSelection,
      searchData: dataWithSelection,
      dataState: "",
      editUser: { display: false },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let dataWithSelection = nextProps.data.map((dataItem) =>
        Object.assign({ selected: false }, dataItem)
      );
      this.setState({
        sort: [{ field: "username", dir: "asc", data: nextProps.data }],
        result: dataWithSelection,
        filterData: dataWithSelection,
        searchData: dataWithSelection,
        dataState: "",
      });
    }
  }

  render() {
    // console.log(this.props.data);
    // console.log(this.state.result);

    // reformat result cater for the display requirement
    let displayResult = JSON.parse(JSON.stringify(this.state.result));
    displayResult = displayResult.map((a) => {
      let last_login = a.last_login
        ? FormatDate(a.last_login, "MM/DD/YYYY HH:MM:SS")
        : "no records";
      let last_modified = FormatDate(a.last_modified, "MM/DD/YYYY HH:MM:SS");
      let last_modified_by = this.props.profileList.filter((user) => {
        return user.username === a.last_modified_by;
      })[0];
      let is_active = a.is_active ? "Active" : "Inactive";
      return {
        ...a,
        is_active_display: is_active,
        last_login: last_login,
        last_modified: last_modified,
        last_modified_by: last_modified_by ? last_modified_by.display_name : "",
      };
    });

    return (
      <div style={userTableWrapper}>
        <div
          style={
            this.state.editUser.display ? BlurEffectStyle : { dummy: "dummy" }
          }
        >
          <div style={tableTitlePadding}>
            <div style={titleStyle}>User Management</div>
            <div className="risk-card-subtitle">
              This manages and register the systems' users
            </div>
          </div>
          <div
            id="table-tool-bar"
            style={{
              marginRight: "1rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
            className="mb-1"
          >
            <div className="mx-1">
              <SearchBar
                searchCallBack={this.onSearchCallBack}
                hint={"Search by user name..."}
              />
            </div>
            <div className="mx-1">
              <IconButtonSolid
                height={"2rem"}
                width={"6rem"}
                color={"Danger"}
                name={"Archive"}
                iconPos={"Left"}
                icon={
                  <IconSVG name={"archive"} size={"0.8"} color={"#ffffff"} />
                }
                clickEvent={() => {
                  //this.props.testSendEmail({
                  //  subject: "123",
                  //  body: "234",
                  //  recipients: ["weidu@kpmg.com.sg"],
                  //});
                }}
              />
            </div>
            <div className="mx-1">
              <IconButtonSolid
                height={"2rem"}
                width={"9rem"}
                iconPos={"Left"}
                icon={
                  <IconSVG name={"newRisk"} size={"0.8"} color={"#ffffff"} />
                }
                name={"Add New User"}
                clickEvent={this.props.handleCreateAccount}
              />
            </div>
          </div>

          <Grid
            style={{ maxHeight: "500px", height: "fit-content" }}
            {...this.state.dataState}
            data={this.customOrderBy(displayResult, this.state.sort)}
            sortable
            onDataStateChange={this.dataStateChange}
            selectedField="selected"
            onSelectionChange={this.selectionChange}
            onHeaderSelectionChange={this.headerSelectionChange}
            onRowClick={this.rowClick}
            sort={this.state.sort}
            rowRender={this.rowRender}
            onSortChange={(e) => {
              var sortConfig = e.sort[0];
              if (!sortConfig) {
                this.setState({
                  sort: [{ data: this.props.data }],
                });
              } else if (sortConfig.field === "formatDateTime") {
                sortConfig = { ...sortConfig, data: this.props.data };
                this.setState({
                  sort: [sortConfig],
                });
              }
            }}
            customizedProps={"123"}
          >
            <Column
              field="selected"
              width="50px"
              headerSelectionValue={
                this.state.result.findIndex(
                  (dataItem) => dataItem.selected === false
                ) === -1
              }
            />
            <Column field="display_name" title="Name" width="170px" />
            <Column field="email" title="Email" width="220px" />
            <Column field="title" title="Title" width="200px" />
            <Column field="is_active_display" title="Active" />
            <Column field="last_login" title="Last Login Time" width="170px" />
            <Column
              field="last_modified"
              title="Last Modified Time"
              width="170px"
            />
            <Column
              field="last_modified_by"
              title="Last Modified By"
              width={"170px"}
            />
            <Column
              title="Edit"
              width="60px"
              cell={(props) => {
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <td
                    onClick={() => {
                      this.handleEditUser(props.dataItem);
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        placeContent: "center",
                        width: "100%",
                      }}
                    >
                      <IconSVG
                        name={"edit"}
                        color={mapping["Color_Basic_Primary"]}
                      />
                    </div>
                  </td>
                );
              }}
            />
            <Column
              title="Delete"
              width="70px"
              cell={(props) => {
                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <td
                    onClick={() => {
                      let payload = JSON.parse(JSON.stringify(props.dataItem));
                      payload.is_active = false;
                      delete payload.email;
                      this.props.handleUpdateUser(payload, [], []);
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        placeContent: "center",
                        width: "100%",
                      }}
                    >
                      <IconSVG
                        name={"delete"}
                        color={mapping["Color_Basic_Primary"]}
                      />
                    </div>
                  </td>
                );
              }}
            />
          </Grid>
        </div>
        {this.state.editUser.display && (
          <UserProfilePopup
            currentUser={this.props.currentUser}
            data={this.state.editUser.data}
            OUList={this.props.OUList}
            roleList={this.props.roleList}
            cancelFunction={this.handleEditUserClose}
            titleList={this.props.titleList}
            handleUpdateUser={this.props.handleUpdateUser}
            accessLevel={this.props.accessLevel}
            userEditRole={this.props.userEditRole}
            handleUpdateUserQueryUserRole={
              this.props.handleUpdateUserQueryUserRole
            }
            handleRoleRequestOUChange={this.props.handleRoleRequestOUChange}
          />
        )}
      </div>
    );
  }

  handleEditUser = (data) => {
    //console.log(data);
    this.props.handleUpdateUserQueryUserRole({
      username: data.username,
      ou_id: this.props.OUList[0].ou_id,
    });
    this.setState({ editUser: { display: true, data: data } });
  };
  handleEditUserClose = () => {
    this.setState({ editUser: { display: false } });
  };
  selectionChange = (event) => {
    const result = this.state.result.map((item) => {
      if (item.username === event.dataItem.username) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    this.setState({ result });
  };
  rowClick = (event) => {
    //console.log(this.props.userList);
    let last = this.lastSelectedIndex;
    const result = [...this.state.result];
    const current = result.findIndex((dataItem) => dataItem === event.dataItem);
    result.forEach((item) => (item.selected = false));
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      result[i].selected = select;
    }
    this.setState({ result });
  };

  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const result = this.state.result.map((item) => {
      item.selected = checked;
      return item;
    });
    this.setState({ result });
  };

  // search related
  onSearchCallBack = (e) => {
    let searchContent = e.target.value;

    let resultData = this.state.filterData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let searchData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );

    if (!searchContent) {
      this.setState({ result: resultData, searchData: searchData });
      return;
    }
    // find the user that suits the search keywords (by display name)
    let userOnSearch = this.state.filterData.filter((userItem) => {
      let matchDisplayName = false;
      if (userItem.display_name) {
        matchDisplayName = userItem.display_name
          .toLowerCase()
          .includes(searchContent.toLowerCase());
      }
      return matchDisplayName;
    });
    this.setState({ searchData: userOnSearch, result: userOnSearch });
  };

  // filter related
  createDataState = (dataState) => {
    let resultData = this.state.searchData.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    let filterData = this.props.data.map((dataItem) =>
      Object.assign({ selected: false }, dataItem)
    );
    return {
      result: process(resultData, dataState).data,
      filterData: process(filterData, dataState).data,
      dataState: dataState,
    };
  };

  dataStateChange = (event) => {
    var datastate = event.data;
    datastate.take = 100;
    this.setState(this.createDataState(datastate));
  };

  // sorting function
  customOrderBy = (data, sort) => {
    let result = [];

    let sortedData = orderBy(data, sort);

    // console.log(sortedData)

    let activeUser = sortedData.filter((user) => {
      return user.is_active;
    });
    let inActiveUser = sortedData.filter((user) => {
      return !user.is_active;
    });

    result.push(...activeUser);
    result.push(...inActiveUser);
    //console.log("sort function");

    //console.log(result);

    return result;
  };

  //archive function
  archiveUser = () => {
    // get all risk with selected
    let selectedUser = this.state.result.filter((user) => {
      return user.selected;
    });
    let archiveUserID = selectedUser.map((a) => a.username);
    this.props.archive(archiveUserID);
  };

  rowRender(trElement, props) {
    const inactive = { backgroundColor: "#00000066" };
    const active = { backgroundColor: "#00000000" };
    const trProps = {
      style: props.dataItem.is_active ? active : inactive,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }
}

const userTableWrapper = {
  marginLeft: "15.6rem",
  marginRight: "15.6rem",
  marginTop: "3.9rem",
  height: "fit-content",
  width: "fit-content",
  backgroundColor: "rgba(0,0,0,0.4)",
};
const tableTitlePadding = {
  paddingLeft: "1rem",
  paddingTop: "1rem",
};

const titleStyle = {
  marginBottom: "1rem",
  ...mapping["heading/h5/lightleft"],
};

const BlurEffectStyle = {
  filter: "blur(5px)",
};
export { UserListTable };
