import React from "react";
import { mapping, ButtonSolid, IconSVG } from "@aim-mf/styleguide";
import { CreateUser } from "./manageUser/createUser/createUser";
import { UserListTable } from "./manageUser/userListTable";
import { navigateToUrl } from "single-spa";
import { ManageOUPage } from "./operatingUnit/manageOUPage";
import { ManageTitlePage } from "./manageTitle/manageTitlePage";
import { LogsTable } from "./logs/logsTable";
import { ManageRolePage } from "./manageRole/manageRolePage";

// this part i think can use AutoComplete from styleguide

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.pageNav,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.pageNav);
    //console.log(this.props.pageNav);
    if (nextProps.pageNav !== this.props.pageNav) {
      this.setState({ page: nextProps.pageNav });
    }
  }

  render() {
    //console.log(this.props.userList);
    let page = this.state.page;
    if (page === "/admin") {
      return (
        <div style={{ width: "102.5rem", margin: "35px 0 0 162px" }}>
          {/* PAGE TITLE  ======================================================================= */}
          <div style={mapping["heading/h3/lightleft"]}>
            {" "}
            Admin Management Console{" "}
          </div>
          <div
            style={{ marginTop: "2rem", display: "flex", flexDirection: "row" }}
          >
            {/* (1) ACCOUNT MGMT ================================================================== */}
            <div style={DarkBoxStyle}>
              <BoxTitle title={"Account Management"} />
              <DescriptionText
                muted={true}
                text={
                  "Manage and create accounts. You can add new accounts in Create Account section."
                }
              />
              <Button
                text={"Manage Accounts"}
                clickEvent={this.handleManageAccounts}
              />
              {this.props.currentUser.access_level < 2 && (
                <Button
                  text={"Manage Business Unit"}
                  clickEvent={this.handleManageOU}
                />
              )}
              <Button
                text={"Manage Title"}
                clickEvent={this.handleManageTitle}
              />
            </div>
            {/* (2) ACCESS MGMT =================================================================== */}
            <div style={DarkBoxStyle}>
              <BoxTitle title={"Access Management"} />
              <DescriptionText
                muted={true}
                text={
                  "Create or modify Policies, Roles and Groups. This affects system wide access. "
                }
              />
              {/*<Button*/}
              {/*  text={"Policy Management"}*/}
              {/*  clickEvent={this.handlePolicyManagement}*/}
              {/*/>*/}
              <Button
                text={"Role Management"}
                clickEvent={this.handleRoleManagement}
              />
              {/*<Button*/}
              {/*  text={"Group Management"}*/}
              {/*  clickEvent={this.handleGroupManagement}*/}
              {/*/>*/}
            </div>
            {/* (3) REPORTS ======================================================================= */}
            <div style={DarkBoxStyle}>
              <BoxTitle title={"Reports"} />
              <DescriptionText
                muted={true}
                text={"View Audit Logs and generate system wide access. "}
              />
              <Button text={"Audit Logs"} clickEvent={this.handleAuditLogs} />
              {/*<Button text={"System Logs"} clickEvent={this.handleSystemLogs} />*/}
            </div>
            {/* (4) SYSTEM MGMT =================================================================== */}
            {/*<div style={DarkBoxStyle}>*/}
            {/*  <BoxTitle title={"System Management"} />*/}
            {/*  <DescriptionText*/}
            {/*    muted={true}*/}
            {/*    text={"Configure System settings and schedule updates. "}*/}
            {/*  />*/}
            {/*  <Button text={"Update"} clickEvent={this.handleUpdates} />*/}
            {/*  <Button*/}
            {/*    text={"Security Settings"}*/}
            {/*    clickEvent={this.handleSecuritySettings}*/}
            {/*  />*/}
            {/*  <Button text={"Restart"} clickEvent={this.handleRestart} />*/}
            {/*  <Button text={"Shutdown"} clickEvent={this.handleShutdown} />*/}
            {/*</div>*/}
            {/* (5) HELP & DOC ==================================================================== */}
            <div style={{ ...DarkBoxStyle, width: "25rem", height: "auto" }}>
              <BoxTitle title={"Help and Document"} />
              <Document
                docName={"Managing Users"}
                docDesc={
                  "This brief provides best practices for tagging resources to make them easier to manage. It discusses common tag categories and strategies to help customers implement a consistent and effective tagging approach for their environment."
                }
                handleOpenDoc={() => {
                  this.handleOpenDoc("Managing users");
                }}
              />
              <Document
                docName={"Creation of Policies and Setting Rules"}
                docDesc={
                  "Users can benefit from monitoring their Work Spaces usage to identify potential time savings. This answer provides and solution that automatically converts Work Spaces to the most cost-effective billing model based on a user’s individual usage."
                }
                handleOpenDoc={() => {
                  this.handleOpenDoc("Creation of Policies and Setting Rules");
                }}
              />
              <Document
                docName={"Generating Reports"}
                docDesc={
                  "This provides full details of creating system access reports and viewing log files."
                }
                handleOpenDoc={() => {
                  this.handleOpenDoc("Generating Reports");
                }}
              />
              <Document
                docName={"System Maintenance Manual"}
                docDesc={
                  "This document provides detailed instructions to update and maintain the system."
                }
                handleOpenDoc={() => {
                  this.handleOpenDoc("System Maintenance Manual");
                }}
              />
              <Document
                docName={"Important Security Manual"}
                docDesc={
                  "This insight can help you make better-informed decisions that increase security and efficiency, facilitate compliance and auditing."
                }
                handleOpenDoc={() => {
                  this.handleOpenDoc("Important Security Manual");
                }}
              />
            </div>
          </div>
        </div>
      );
    } else if (page === "/admin/createUser") {
      return (
        <div>
          <CreateUser
            currentUser={this.props.currentUser}
            OUList={this.props.OUList}
            aimBaseUrl={this.props.aimBaseUrl}
            roleList={this.props.roleList}
            userRole={this.props.userRole}
            titleList={this.props.titleList}
            accessLevel={this.props.accessLevel}
            handleUserRoleRequestOUChange={
              this.props.handleUserRoleRequestOUChange
            }
            handleRoleRequestOUChange={this.props.handleRoleRequestOUChange}
            handleCreateUser={this.props.handleCreateUser}
          />
        </div>
      );
    } else if (page === "/admin/manageUser") {
      return (
        <div>
          <UserListTable
            currentUser={this.props.currentUser}
            profileList={this.props.profileList}
            data={this.props.userList}
            roleList={this.props.roleList}
            OUList={this.props.OUList}
            handleRoleRequestOUChange={this.props.handleRoleRequestOUChange}
            userEditRole={this.props.userEditRole}
            handleUpdateUserQueryUserRole={
              this.props.handleUpdateUserQueryUserRole
            }
            accessLevel={this.props.accessLevel}
            titleList={this.props.titleList}
            handleUpdateUser={this.props.handleUpdateUser}
            handleCreateAccount={this.handleCreateAccount}
            testSendEmail={this.props.testSendEmail}
            //addNew={this.createRiskHandler}
            //archive={this.props.handleArchiveRisk}
            //rights={this.props.rights}
          />
        </div>
      );
    } else if (page === "/admin/manageOU") {
      return (
        <div>
          <ManageOUPage
            OUData={this.props.OUList}
            roleData={this.props.roleList}
            titleData={this.props.titleList}
            titleList={this.props.titleList}
            handleUpdateUser={this.props.handleUpdateUser}
            handleCreateOU={this.props.handleCreateOU}
            handleDeleteOU={this.props.handleDeleteOU}
            handleEditOU={this.props.handleEditOU}
            handleCreateTitle={this.props.handleCreateTitle}
            handleDeleteTitle={this.props.handleDeleteTitle}
            handleEditTitle={this.props.handleEditTitle}
            handleCreateRole={this.props.handleCreateRole}
            handleDeleteRole={this.props.handleDeleteRole}
            handleEditRole={this.props.handleEditRole}
          />
        </div>
      );
    } else if (page === "/admin/manageTitle") {
      return (
        <div>
          <ManageTitlePage
            OUData={this.props.OUList}
            roleData={this.props.roleList}
            titleData={this.props.titleList}
            titleList={this.props.titleList}
            handleUpdateUser={this.props.handleUpdateUser}
            handleCreateOU={this.props.handleCreateOU}
            handleDeleteOU={this.props.handleDeleteOU}
            handleEditOU={this.props.handleEditOU}
            handleCreateTitle={this.props.handleCreateTitle}
            handleDeleteTitle={this.props.handleDeleteTitle}
            handleEditTitle={this.props.handleEditTitle}
            handleCreateRole={this.props.handleCreateRole}
            handleDeleteRole={this.props.handleDeleteRole}
            handleEditRole={this.props.handleEditRole}
          />
        </div>
      );
    } else if (page === "/admin/manageRole") {
      return (
        <ManageRolePage
          OUList={this.props.OUList}
          roleQuery={this.props.roleQuery}
          permissionList={this.props.permissionList}
          roleData={this.props.roleList}
          handleCreateRole={this.props.handleCreateRole}
          handleDeleteRole={this.props.handleDeleteRole}
          handleEditRole={this.props.handleEditRole}
          handleRoleRequestOUChange={this.props.handleRoleRequestOUChange}
          handlePermissionListQuery={this.props.handlePermissionListQuery}
          handleRoleQuery={this.props.handleRoleQuery}
        />
      );
    } else if (page === "/admin/logs") {
      return (
        <LogsTable
          OUList={this.props.OUList}
          data={this.props.log}
          handleLogRequest={this.props.handleLogRequest}
        />
      );
    }
  }
  // 1 - ACCOUNT MANAGEMENT BUTTONS
  handleCreateAccount = () => {
    navigateToUrl(this.props.aimBaseUrl + "admin/createUser");
  };
  handleManageAccounts = () => {
    navigateToUrl(this.props.aimBaseUrl + "admin/manageUser");
  };
  handleManageOU = () => {
    navigateToUrl(this.props.aimBaseUrl + "admin/manageOU");
  };
  handleManageTitle = () => {
    navigateToUrl(this.props.aimBaseUrl + "admin/manageTitle");
  };
  // 2 - ACCESS MANAGEMENT BUTTONS
  handlePolicyManagement = () => {};
  handleRoleManagement = () => {
    navigateToUrl(this.props.aimBaseUrl + "admin/manageRole");
  };
  handleGroupManagement = () => {};
  // 3 - REPORT BUTTONS
  handleAuditLogs = () => {
    navigateToUrl(this.props.aimBaseUrl + "admin/logs");
  };
  handleSystemLogs = () => {};
  // 4 - SYSTEM MANAGEMENT BUTTONS
  handleUpdates = () => {};
  handleSecuritySettings = () => {};
  handleRestart = () => {};
  handleShutdown = () => {};
  handleOpenDoc = (link) => {
    // eslint-disable-next-line no-console
    console.log("Open this file link: ", link);
  };
}
const DarkBoxStyle = {
  backgroundColor: mapping["Color_BG_03_trans"],
  width: "18rem",
  height: "22rem",
  borderRadius: "4px",
  marginRight: "1.1rem",
  padding: "1.1rem",
  display: "flex",
  flexDirection: "column",
};
const BoxTitle = (props) => {
  return (
    <div style={{ ...mapping["heading/h5/lightleft"], marginBottom: "1.5rem" }}>
      {" "}
      {props.title}{" "}
    </div>
  );
};
const DescriptionText = (props) => {
  return (
    <div
      style={{
        ...mapping["paragraph/small/mutedleft"],
        color: props.muted === true ? "#86909A" : "white",
        marginBottom: "1.5rem",
      }}
    >
      {" "}
      {props.text}{" "}
    </div>
  );
};
const Button = (props) => {
  return (
    <div style={{ marginBottom: "1.2rem" }}>
      <ButtonSolid
        name={props.text}
        size={"Small"}
        width={"15.8rem"}
        height={"2rem"}
        color={"Primary"}
        clickEvent={props.clickEvent}
      />
    </div>
  );
};
const Document = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0.3rem",
        paddingRight: "1rem",
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={"div_hover"}
        style={{
          display: "flex",
          flexDirection: "row",
          width: "fit-content",
          height: "2rem",
        }}
        onClick={props.handleOpenDoc}
      >
        <div style={{ width: "2rem" }}>
          <IconSVG
            name={"file"}
            size={"1"}
            color={mapping["Color_Basic_Info"]}
          />
        </div>
        <div
          style={{
            ...mapping["forms/label/1-default"],
            color: mapping["Color_Basic_Info"],
            marginTop: "0.2rem",
          }}
        >
          {props.docName}
        </div>
      </div>
      <div style={{ marginLeft: "2.1rem" }}>
        <DescriptionText text={props.docDesc} />
      </div>
    </div>
  );
};

export { AdminDashboard };
