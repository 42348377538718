import React from "react";
import { mapping, ButtonSolid } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

// can check how i did for blur effect in risk management, on file "risk Management"
class CreationCompletePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div name={"CreationComplete"} style={creationPageStyle}>
        <div style={creationCompleteStyle}>
          <div style={accountCreatedStyle}>Account Successfully Created</div>
          <div style={lineStyle}></div>
          <div style={emailSentStyle}>
            An email notification was sent to {this.props.email}
          </div>
          <div style={buttonPosition}>
            <ButtonSolid
              name={"Back to Dashboard"}
              width={"150px"}
              color={"Primary"}
              clickEvent={() => {
                navigateToUrl(this.props.aimBaseUrl + "admin");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const creationPageStyle = {
  margin: "-20rem auto",
  textAlign: "left",
  color: "white",
};
const creationCompleteStyle = {
  margin: "auto",
  width: "30%",
  fontSize: 18,
  backgroundColor: "#283A5D",
  boxShadow: "0px 0px 5px 0.2px black",
  padding: "0.5rem 0",
  zIndex: 4,
  position: "relative",
};
const accountCreatedStyle = {
  marginLeft: "1rem",
  fontSize: 16,
  marginBottom: "0.5rem",
  marginTop: "0.75rem",
  fontStyle: "bold",
};
const emailSentStyle = {
  marginLeft: "1rem",
  fontSize: 12,
  marginTop: "0.75rem",
  marginBottom: "1.75rem",
  fontStyle: "bold",
};
const lineStyle = {
  borderWidth: "1px",
  borderColor: "#777171",
  zIndex: 4,
  position: "relative",
};
const buttonPosition = { textAlign: "right", marginRight: "1rem" };
const buttonStyle = {
  backgroundColor: "#00A2FF",
  zIndex: 4,
  position: "relative",
  borderRadius: "3px",
  padding: "5px",
};
export { CreationCompletePopup };
