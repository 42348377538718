import React from "react";
import {
  mapping,
  NonValidationInput,
  ButtonSolid,
  DropDownListButton,
  CustomizedCheckBox,
} from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class RoleEditPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ou_id: this.props.data.ou_id,
      frontend: [],
      display_name: this.props.data.display_name,
      permission: {},
    };
  }

  componentDidMount() {
    this.props.handlePermissionListQuery({ ou_id: this.state.ou_id });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.permissionList);
    if (
      JSON.stringify(nextProps.permissionList.entity) !==
        JSON.stringify(this.props.permissionList.entity) ||
      JSON.stringify(nextProps.roleQuery) !==
        JSON.stringify(this.props.roleQuery)
    ) {
      // console.log(nextProps)
      if (nextProps.permissionList.length === 0) {
      } else {
        let permission = {};
        if (
          Object.keys(nextProps.roleQuery).length !== 0 &&
          Object.keys(nextProps.roleQuery.permission).length !== 0
        ) {
          nextProps.permissionList.entity.forEach((entity) => {
            let dbPermission = nextProps.roleQuery.permission[
              entity.entity_id
            ].toString(2);
            dbPermission = ("0000000000" + dbPermission).slice(-10);
            let create = dbPermission[9];
            let view = dbPermission[8];
            let edit = dbPermission[7];
            let Delete = dbPermission[6];
            let approve = dbPermission[5];
            permission[entity.entity_id] = {
              create: create === "1",
              view: view === "1",
              edit: edit === "1",
              delete: Delete === "1",
              approve: approve === "1",
            };
          });
        } else {
          nextProps.permissionList.entity.forEach((entity) => {
            permission[entity.entity_id] = {
              create: false,
              view: false,
              edit: false,
              delete: false,
              approve: false,
            };
          });
        }
        //console.log(permission);
        this.setState({ permission: permission });
      }
    }
  }

  render() {
    //console.log(this.props.permissionList)
    if (this.props.permissionList.length === 0) {
      return <div />;
    }
    return (
      <div style={RiskCreationFormWrapStyle}>
        <div style={RiskCreationFormStyle}>
          <div style={formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>Create a new role</div>
            <div style={{ height: "50px" }} />
            <DropDownListButton
              title={"Business Entity"}
              data={this.props.OUData.map((ou) => ou.display_name)}
              value={
                this.props.OUData.filter(
                  (ou) => ou.ou_id === this.state.ou_id
                )[0].display_name
              }
              DropDownButtonStyle={OUDropDownButtonStyle}
              CustomOnChange={this.handleOUChange}
            />

            <div style={fieldPadding} />
            <div style={mapping["forms/label/1-default"]}>Role name</div>
            <NonValidationInput
              fieldstyle={InputFieldStyle}
              value={this.state.display_name}
              width={"100%"}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ display_name: event.value });
              }}
            />
            <div style={fieldPadding} />
            <div style={mapping["forms/label/1-default"]}>Role Rights</div>

            {this.props.permissionList.entity.map((entity) => {
              return (
                <RightsSelection
                  data={entity}
                  roleQuery={this.props.roleQuery}
                  handlePermissionCreateChange={
                    this.handlePermissionCreateChange
                  }
                  handlePermissionViewChange={this.handlePermissionViewChange}
                  handlePermissionDeleteChange={
                    this.handlePermissionDeleteChange
                  }
                  handlePermissionEditChange={this.handlePermissionEditChange}
                  handlePermissionApproveChange={
                    this.handlePermissionApproveChange
                  }
                />
              );
            })}
            <div style={{ height: "40px" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={"79px"}
                height={"39px"}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Update Role"}
                color={"Primary"}
                width={"120px"}
                height={"39px"}
                clickEvent={this.handleRoleUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleRoleUpdate = () => {
    //['Create',
    // 'View',
    // 'Edit',
    // 'Delete',
    // 'Approve',
    // 'CreateChild',
    // 'ViewChild',
    // 'EditChild',
    // 'DeleteChild',
    // 'ApproveChild']
    let permission = {};
    for (let key in this.state.permission) {
      let value = this.state.permission[key];
      let permissiondecimal =
        (value.create ? 1 : 0) +
        ((value.view ? 1 : 0) << 1) +
        ((value.edit ? 1 : 0) << 2) +
        ((value.delete ? 1 : 0) << 3) +
        ((value.approve ? 1 : 0) << 4) +
        ((value.create ? 1 : 0) << 5) +
        ((value.view ? 1 : 0) << 6) +
        ((value.edit ? 1 : 0) << 7) +
        ((value.delete ? 1 : 0) << 8) +
        ((value.approve ? 1 : 0) << 9);
      permission[key.toString()] = permissiondecimal;
    }
    let roleCreationPayload = {
      ou_id: this.state.ou_id,
      role_id: this.props.data.role_id,
      frontend: [],
      display_name: this.state.display_name,
      permission: permission,
    };
    //console.log(roleCreationPayload);
    this.props.submitFunction(roleCreationPayload);
    this.props.cancelFunction();
  };

  handleOUChange = (event) => {
    let ou_name = event.value;
    let ou_id = this.props.OUData.filter((OU) => {
      return OU.display_name === ou_name;
    })[0].ou_id;
    this.setState({ ou_id: ou_id });
  };
  handlePermissionCreateChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].create = !permission[entityID].create;
    this.setState({ permission: permission });
  };
  handlePermissionViewChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].view = !permission[entityID].view;
    this.setState({ permission: permission });
  };
  handlePermissionEditChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].edit = !permission[entityID].edit;
    this.setState({ permission: permission });
  };
  handlePermissionDeleteChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].delete = !permission[entityID].delete;
    this.setState({ permission: permission });
  };
  handlePermissionApproveChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].approve = !permission[entityID].approve;
    this.setState({ permission: permission });
  };
}

const RightsSelection = (props) => {
  //console.log(props);
  let permission = 0;
  let create = false;
  let view = false;
  let edit = false;
  let Delete = false;
  let approve = false;
  if (
    Object.keys(props.roleQuery).length !== 0 &&
    Object.keys(props.roleQuery.permission).length !== 0
  ) {
    permission = props.roleQuery.permission[props.data.entity_id].toString(2);
    permission = ("0000000000" + permission).slice(-10);
    //console.log(permission);
    create = permission[9];
    view = permission[8];
    edit = permission[7];
    Delete = permission[6];
    approve = permission[5];
  }
  return (
    <div>
      <div style={fieldTitleStyle}>
        {props.data.display_name} ({props.data.service})
      </div>
      <div style={fieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>Create</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionCreateChange(props.data.entity_id);
            }}
            value={create === "1"}
          />
        </div>
      </div>
      <div style={halfFieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>View</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionViewChange(props.data.entity_id);
            }}
            value={view === "1"}
          />
        </div>
      </div>
      <div style={halfFieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>Edit</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionEditChange(props.data.entity_id);
            }}
            value={edit === "1"}
          />
        </div>
      </div>
      <div style={halfFieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>Delete</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionDeleteChange(props.data.entity_id);
            }}
            value={Delete === "1"}
          />
        </div>
      </div>
      <div style={halfFieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>Approve</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionApproveChange(props.data.entity_id);
            }}
            value={approve === "1"}
          />
        </div>
      </div>
    </div>
  );
};

const OUDropDownButtonStyle = {
  width: "100%",
  height: "40px",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const fieldTitleStyle = {
  ...mapping["Form_Label_Padding"],
  display: "flex",
  paddingBottom: "9px",
  color: mapping["Color_Basic_Light"],
};

const checkboxStyle = {
  display: "inline-flex",
  justifyContent: "space-between",
  width: "100%",
};

const fieldPadding = {
  height: "20px",
};
const halfFieldPadding = {
  height: "10px",
};
const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "100%",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const formBgStyle = {
  height: "fit-content",
  width: "450px",
  padding: "23px 18px 43px 18px",
  backgroundColor: mapping["Color_Extra_Half_Transparent"],
  //display: "inline-flex",
  position: "relative",
};
const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "13.3rem",
};

export { RoleEditPopUp };
