import React from "react";
import {
  mapping,
  NonValidationInput,
  ButtonSolid,
  DropDownListButton,
  CustomizedCheckBox,
} from "@aim-mf/styleguide";

// this part i think can use AutoComplete from styleguide

class RoleCreationPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ou_id: this.props.OUData.map((ou) => ou.ou_id)[0],
      frontend: [],
      display_name: "",
      permission: {},
    };
  }

  componentDidMount() {
    this.props.handlePermissionListQuery({ ou_id: this.state.ou_id });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.permissionList);
    if (
      JSON.stringify(nextProps.permissionList.entity) !==
      JSON.stringify(this.props.permissionList.entity)
    ) {
      let permission = {};
      nextProps.permissionList.entity.forEach((entity) => {
        permission[entity.entity_id] = {
          create: false,
          view: false,
          edit: false,
          delete: false,
          approve: false,
        };
      });
      //console.log(permission);
      this.setState({ permission: permission });
    }
  }

  render() {
    //console.log(this.props.permissionList)
    if (this.props.permissionList.length === 0) {
      return <div />;
    }
    return (
      <div style={RiskCreationFormWrapStyle}>
        <div style={RiskCreationFormStyle}>
          <div style={formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>Create a new role</div>
            <div style={{ height: "50px" }} />
            <DropDownListButton
              title={"Business Entity"}
              data={this.props.OUData.map((ou) => ou.display_name)}
              value={this.props.OUData.map((ou) => ou.display_name)[0]}
              DropDownButtonStyle={OUDropDownButtonStyle}
              CustomOnChange={this.handleOUChange}
            />

            <div style={fieldPadding} />
            <div style={mapping["forms/label/1-default"]}>Role name</div>
            <NonValidationInput
              fieldstyle={InputFieldStyle}
              width={"100%"}
              minRows={3}
              onChange={(event) => {
                // eslint-disable-next-line no-console
                this.setState({ display_name: event.value });
              }}
            />

            <div style={fieldPadding} />
            <div style={mapping["forms/label/1-default"]}>Role Rights</div>

            {this.props.permissionList.entity.map((entity) => {
              return (
                <RightsSelection
                  data={entity}
                  handlePermissionCreateChange={
                    this.handlePermissionCreateChange
                  }
                  handlePermissionViewChange={this.handlePermissionViewChange}
                  handlePermissionDeleteChange={
                    this.handlePermissionDeleteChange
                  }
                  handlePermissionEditChange={this.handlePermissionEditChange}
                  handlePermissionApproveChange={
                    this.handlePermissionApproveChange
                  }
                />
              );
            })}
            <div style={{ height: "40px" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={"79px"}
                height={"39px"}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Create Role"}
                color={"Primary"}
                width={"120px"}
                height={"39px"}
                clickEvent={this.handleRoleCreation}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleRoleCreation = () => {
    let permission = {};
    for (let key in this.state.permission) {
      let value = this.state.permission[key];
      let permissiondecimal =
        (value.create ? 1 : 0) +
        ((value.view ? 1 : 0) << 1) +
        ((value.edit ? 1 : 0) << 2) +
        ((value.delete ? 1 : 0) << 3) +
        ((value.approve ? 1 : 0) << 4) +
        ((value.create ? 1 : 0) << 5) +
        ((value.view ? 1 : 0) << 6) +
        ((value.edit ? 1 : 0) << 7) +
        ((value.delete ? 1 : 0) << 8) +
        ((value.approve ? 1 : 0) << 9);
      permission[key.toString()] = permissiondecimal;
    }
    let roleCreationPayload = {
      ou_id: this.state.ou_id,
      frontend: ["dashboard", "my_task", "risk_management"],
      display_name: this.state.display_name,
      permission: permission,
    };
    //console.log(roleCreationPayload);
    this.props.submitFunction(roleCreationPayload);
    this.props.cancelFunction();
  };

  handleOUChange = (event) => {
    let ou_name = event.value;
    let ou_id = this.props.OUData.filter((OU) => {
      return OU.display_name === ou_name;
    })[0].ou_id;
    this.setState({ ou_id: ou_id });
  };
  handlePermissionCreateChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].create = !permission.create;
    this.setState({ permission: permission });
  };
  handlePermissionViewChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].view = !permission.view;
    this.setState({ permission: permission });
  };
  handlePermissionEditChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].edit = !permission.edit;
    this.setState({ permission: permission });
  };
  handlePermissionDeleteChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].delete = !permission.delete;
    this.setState({ permission: permission });
  };
  handlePermissionApproveChange = (entityID) => {
    let permission = this.state.permission;
    permission[entityID].approve = !permission.approve;
    this.setState({ permission: permission });
  };
}

const RightsSelection = (props) => {
  return (
    <div>
      <div style={fieldTitleStyle}>
        {props.data.display_name} ({props.data.service})
      </div>
      <div style={fieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>Create</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionCreateChange(props.data.entity_id);
            }}
            value={false}
          />
        </div>
      </div>
      <div style={halfFieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>View</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionViewChange(props.data.entity_id);
            }}
            value={false}
          />
        </div>
      </div>
      <div style={halfFieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>Edit</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionEditChange(props.data.entity_id);
            }}
            value={false}
          />
        </div>
      </div>
      <div style={halfFieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>Delete</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionDeleteChange(props.data.entity_id);
            }}
            value={false}
          />
        </div>
      </div>
      <div style={halfFieldPadding} />
      <div>
        <div style={checkboxStyle}>
          <div style={fieldTitleStyle}>Approve</div>
          <CustomizedCheckBox
            onCheckChange={() => {
              props.handlePermissionApproveChange(props.data.entity_id);
            }}
            value={false}
          />
        </div>
      </div>
    </div>
  );
};

const OUDropDownButtonStyle = {
  width: "100%",
  height: "40px",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const fieldTitleStyle = {
  ...mapping["Form_Label_Padding"],
  display: "flex",
  paddingBottom: "9px",
  color: mapping["Color_Basic_Light"],
};

const checkboxStyle = {
  display: "inline-flex",
  justifyContent: "space-between",
  width: "100%",
};

const fieldPadding = {
  height: "20px",
};
const halfFieldPadding = {
  height: "10px",
};
const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "100%",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const formBgStyle = {
  height: "fit-content",
  width: "450px",
  padding: "23px 18px 43px 18px",
  backgroundColor: mapping["Color_Extra_Half_Transparent"],
  //display: "inline-flex",
  position: "relative",
};
const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "13.3rem",
};

export { RoleCreationPopUp };
