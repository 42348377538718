import React from "react";
import { mapping, ButtonSolid } from "@aim-mf/styleguide";
import { UserRoleAssign } from "./userRoleAssign";
import { UserAccountDetail } from "./userAccountDetail";
import { CreationCompletePopup } from "./creationCompletePopup";
import { UserProfilePopup } from "../userProfilePopup";

// few points to note:
// 1. rmb, every style uses variables, dun just push inside the html tag, so later it is easier for me to change.
// 2. react style is different from html style, basicly, the react style is CamelCase,   e.g.==>  html: background-color,   react: backgroundColor,
// 3. if you wanna some good example, you could update your risk-edit git repo, and find file "riskRating.js" under src/src/page/component
// 4. all dummy data should come from this file, and aggregate (if necessary) to this file. (i have created the variable testUserList for you)
const testUserList = [
  {
    id: 1,
    display_name: "Janice Tan",
    designation: "",
    phone: "",
    email: "",
    initial: "", // this is what displayed on the Avatar (namecard)
    pic: "", // this is for avatar profile picture, leave this as blank for now
    department: "",
  },
  {
    id: 2,
    display_name: "Juan Cruz",
    designation: "",
    phone: "",
    email: "",
    initial: "", // this is what displayed on the Avatar (namecard)
    pic: "", // this is for avatar profile picture, leave this as blank for now
    department: "",
  },
  {
    id: 3,
    display_name: "Jony Williams",
    designation: "Director, Audit",
    phone: "+65 9876 7865",
    email: "jonywilliams@fortunehappy.sg",
    initial: "JW", // this is what displayed on the Avatar (namecard)
    pic: "https://via.placeholder.com/150",
    // this is for avatar profile picture, leave this as blank for now
    department: "Audit Department",
  },
  {
    id: 4,
    display_name: "Jonas Ang",
    designation: "",
    phone: "",
    email: "",
    initial: "", // this is what displayed on the Avatar (namecard)
    pic: "", // this is for avatar profile picture, leave this as blank for now
    department: "",
  },
];

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      created: false,
      userList: testUserList,
      userID: -1,
      newUserData: {},
    };
  }

  render() {
    //console.log(this.props.userList);
    return (
      <div name={"Create Account"}>
        <div style={this.state.created ? BlurEffectStyle : titleStyle}>
          Create Account
        </div>
        <div style={this.state.created ? BlurEffectStyle : titleStyle}>
          <div style={this.state.step >= 1 ? stepHighlightedStyle : stepStyle}>
            1
          </div>
          <div
            style={this.state.step >= 2 ? lineStyleHighlighted : lineStyle}
          />
          <div style={this.state.step >= 2 ? stepHighlightedStyle : stepStyle}>
            2
          </div>
        </div>
        <div style={this.state.created ? BlurEffectStyle : titleStyle}>
          <div
            style={this.state.step >= 1 ? searchUserHighlighted : searchUser}
          >
            User Account Detail
          </div>
          <div style={lineBetweenTitle} />
          <div
            style={this.state.step >= 2 ? searchUserHighlighted : searchUser}
          >
            Add User Rights
          </div>
        </div>
        <div>
          <div
            style={this.state.created ? BlurEffetStyle2 : { dummy: "dummy" }}
          >
            {this.state.step === 1 && (
              <UserAccountDetail
                currentUser={this.props.currentUser}
                titleList={this.props.titleList}
                handleNext={this.handleNext}
                handlePrevious={this.handlePrevious}
                accessLevel={this.props.accessLevel}
                saveNewUserInfo={(newUserData) => {
                  this.setState({ newUserData: newUserData });
                }}
              />
            )}
            {this.state.step === 2 && (
              <UserRoleAssign
                handleAddUser={this.handleAddUser}
                handlePrevious={this.handlePrevious}
                OUList={this.props.OUList}
                roleList={this.props.roleList}
                userRole={this.props.userRole}
                handleUserRoleRequestOUChange={
                  this.props.handleUserRoleRequestOUChange
                }
                handleRoleRequestOUChange={this.props.handleRoleRequestOUChange}
              />
            )}
          </div>
          <div>
            {this.state.created === true && this.state.step === 2 && (
              <CreationCompletePopup
                email={this.state.newUserData.email}
                aimBaseUrl={this.props.aimBaseUrl}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  handleNext = () => {
    this.setState((prevState) => {
      return { step: prevState.step + 1 };
    });
  };
  handlePrevious = () => {
    this.setState((prevState) => {
      return { step: prevState.step - 1 };
    });
  };
  handleAddUser = (roles) => {
    //console.log(roles);
    this.setState(() => {
      return { created: true };
    });
    this.props.handleCreateUser(this.state.newUserData, roles);
  };
}
const titleStyle = {
  ...mapping["heading/h3/lightcenter"],
  marginTop: "1.5rem",
  fontSize: 28,
};
const stepStyle = {
  ...mapping["heading/h3/lightcenter"],
  height: "40px",
  width: "40px",
  padding: "0.2rem 0.2rem 0.2rem 0.2rem",
  display: "inline-block",
  fontSize: 20,
  borderRadius: "50%",
  border: "solid 2.5px",
  borderColor: "#999999",
  color: "#999999",
  fontWeight: "bold",
};
const stepHighlightedStyle = {
  ...stepStyle,
  borderColor: "#00A2FF",
  color: "white",
  fontWeight: "bold",
};
const lineStyle = {
  borderWidth: "1px",
  width: "116px",
  display: "inline-block",
  borderStyle: "dashed",
  borderColor: "#999999",
};
const lineStyleHighlighted = {
  borderWidth: "1px",
  width: "116px",
  display: "inline-block",
  borderStyle: "dashed",
  borderColor: "#00A2FF",
};
const lineBetweenTitle = {
  width: "60px",
  display: "inline-block",
  borderStyle: "dashed",
  borderColor: "white",
};
const searchUser = {
  ...mapping["heading/h3/lightcenter"],
  width: "100px",
  display: "inline-block",
  fontSize: 16,
  overflow: "auto",
  color: "#999999",
};
const searchUserHighlighted = {
  ...mapping["heading/h3/lightcenter"],
  width: "100px",
  display: "inline-block",
  fontSize: 16,
  overflow: "auto",
};
const BlurEffectStyle = {
  filter: "blur(20px)",
  ...mapping["heading/h3/lightcenter"],
  marginTop: "1.5rem",
};
const BlurEffetStyle2 = {
  filter: "blur(20px)",
};

export { CreateUser };
